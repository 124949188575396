import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import isEqual from 'lodash-es/isEqual';
import { setOwner } from '@ember/application';
import { inject as service } from '@ember/service';

const SELECTED_COLUMNS_MAX = 13;

const defaultColumnNames = [
  'keyword-position',
  'keyword-mini-graph',
  'keyword-dwm-change',
  'keyword-sv-global',
  'keyword-tags',
];

const urlColumns = [
  {
    name: 'keyword-tags',
    displayName: 'Tags',
  },
  {
    name: 'keyword-position',
    displayName: 'Rank',
    sortProperty: 'position',
  },
  {
    name: 'keyword-position-organic',
    displayName: 'Organic Rank',
    sortProperty: 'position_organic',
  },
  {
    name: 'keyword-position-local-pack',
    displayName: 'Local Pack Rank',
    sortProperty: 'position_local_pack',
  },
  {
    name: 'keyword-position-previous',
    displayName: 'Previous Rank',
    sortProperty: 'previous_position',
  },
  {
    name: 'keyword-position-best',
    displayName: 'Best Rank',
    sortProperty: 'best_position',
  },
  {
    name: 'keyword-created-at',
    displayName: 'Added On',
    sortProperty: 'created_at',
  },
  {
    name: 'keyword-last-update',
    displayName: 'Last Updated',
    sortProperty: 'last_processed_at',
  },
  {
    name: 'keyword-last-position-change',
    displayName: 'Last Changed',
    sortProperty: 'position_changed_by',
  },
  {
    name: 'keyword-mini-graph',
    displayName: 'Evolution Graph',
    sortProperty: 'position_changed_during_last_day',
  },
  {
    name: 'keyword-search-engine',
    displayName: 'Search Engine',
    sortProperty: 'engine',
  },
  {
    name: 'keyword-location',
    displayName: 'Location',
    sortProperty: 'google_gl',
  },
  {
    name: 'keyword-language',
    displayName: 'Language',
    sortProperty: 'google_hl',
  },
  {
    name: 'keyword-dwm-change',
    displayName: 'D/W/M Change',
    sortProperty: 'position_changed_during_last_day',
  },
  {
    name: 'position-changed-during-last-day',
    displayName: 'Daily Change',
    sortProperty: 'position_changed_during_last_day',
  },
  {
    name: 'position-changed-during-last-week',
    displayName: 'Weekly Change',
    sortProperty: 'position_changed_during_last_week',
  },
  {
    name: 'position-changed-during-last-month',
    displayName: 'Monthly Change',
    sortProperty: 'position_changed_during_last_month',
  },
  {
    name: 'keyword-results-count',
    displayName: 'SERP Results',
    sortProperty: 'results_count',
  },
  {
    name: 'keyword-sv-local',
    headerName: 'SV (local)',
    displayName: 'Search Volume (local)',
    sortProperty: 'adwords_local_search_volume',
  },
  {
    name: 'keyword-sv-global',
    headerName: 'SV (global)',
    displayName: 'Search Volume (global)',
    sortProperty: 'adwords_global_search_volume',
  },
  {
    name: 'keyword-cpc-local',
    headerName: 'CPC (local)',
    displayName: 'Avg. CPC (local)',
    sortProperty: 'adwords_local_average_cpc',
  },
  {
    name: 'keyword-cpc-global',
    headerName: 'CPC (global)',
    displayName: 'Avg. CPC (global)',
    sortProperty: 'adwords_global_average_cpc',
  },
  {
    name: 'keyword-url',
    displayName: 'Tracking URL',
    sortProperty: 'url',
  },
  {
    name: 'keyword-clicks',
    displayName: 'Daily Clicks',
    sortProperty: 'clicks',
  },
  {
    name: 'keyword-impressions',
    displayName: 'Daily Impressions',
    sortProperty: 'impressions',
  },
  {
    name: 'keyword-ctr',
    displayName: 'Daily CTR',
    sortProperty: 'ctr',
  },
  {
    name: 'keyword-position-places-image',
    displayName: 'Image Carousel Rank',
    sortProperty: 'position_places_image',
  },
  {
    name: 'keyword-search-console-position',
    displayName: 'Search Console Rank',
    sortProperty: 'search_console_position',
  },
  {
    name: 'serp-opportunities',
    displayName: 'Available SERP Features',
    sortProperty: 'available_serp_features',
  },
];

export const competitorColumns = [
  {
    name: 'keyword-position',
    displayName: 'Rank',
    sortProperty: 'rank',
  },
  {
    name: 'keyword-position-organic',
    displayName: 'Organic Rank',
    sortProperty: 'position_organic',
  },
  {
    name: 'keyword-position-places-image',
    displayName: 'Image carousel rank',
    sortProperty: 'position_places_image',
  },
  {
    name: 'keyword-position-local-pack',
    displayName: 'Local pack rank',
    sortProperty: 'position_local_pack',
  },
  {
    name: 'keyword-last-position-change',
    displayName: 'Last Change',
  },
  {
    name: 'keyword-dwm-change',
    displayName: 'D/W/M Change',
  },
];

export const dynamicColumns = [
  {
    name: 'traffic-value-selected-interval',
    displayName: 'Traffic Value',
    sortProperty: 'traffic_value_selected_interval',
  },
  {
    name: 'traffic-value-change',
    displayName: 'Traffic Value Change',
    sortProperty: 'traffic_value_change',
  },
  {
    name: 'click-potential-selected-interval',
    displayName: 'Click Potential',
    sortProperty: 'click_potential_selected_interval',
  },
  {
    name: 'click-potential-change',
    displayName: 'Click Potential Change',
    sortProperty: 'click_potential_change',
  },
  {
    name: 'search-volatility-selected-interval',
    displayName: 'Search Volatility',
    sortProperty: 'search_volatility_selected_interval',
  },
  {
    name: 'search-volatility-change',
    displayName: 'Search Volatility Change',
    sortProperty: 'search_volatility_change',
  },
  {
    name: 'average-position-selected-interval',
    displayName: 'Average Position',
    sortProperty: 'average_position_selected_interval',
  },
  {
    name: 'average-position-change',
    displayName: 'Average Position Change',
    sortProperty: 'average_position_change',
  },
];

export default class KeywordTableColumns {
  @service notifications;

  default = {
    name: 'keyword-query',
    displayName: 'Keywords',
    sortProperty: 'query',
  };

  @tracked competitors = [];
  @tracked selected = [];
  @tracked dropTarget;
  notification;

  constructor({ owner, saveColumnNames }) {
    setOwner(this, owner);
    this.saveColumnNames = saveColumnNames;
  }

  init(loadedColumnNames, competitors) {
    this.competitors = competitors;
    this.selected = (loadedColumnNames ?? defaultColumnNames)
      .map((columnName) => this.allColumns.findBy('name', columnName))
      .uniq()
      .compact();
  }

  get allColumns() {
    return []
      .concat(urlColumns)
      .concat(this.competitorColumns)
      .concat(dynamicColumns);
  }

  get switcherOptions() {
    return []
      .concat(this.withSelected(urlColumns))
      .concat(this.competitorSwitcherOptions);
  }

  get dynamicSwitcherOptions() {
    return [].concat(this.withSelected(dynamicColumns));
  }

  get competitorColumns() {
    return (
      this.competitors?.toArray().flatMap((competitor) => {
        return competitorColumns.flatMap((column) => {
          return {
            ...column,
            name: `${column.name}-competitor-${competitor.id}`,
            competitor,
            sortProperty: column.sortProperty
              ? `${column.sortProperty}_competitor_${competitor.id}`
              : undefined,
          };
        });
      }) ?? []
    );
  }

  get competitorSwitcherOptions() {
    return (
      this.competitors?.toArray().map((competitor) => {
        const groupName = `Competitor: ${competitor?.displayName}`;
        const options = this.withSelected(
          this.competitorColumns.filterBy('competitor', competitor)
        );
        return { groupName, options };
      }) ?? []
    );
  }

  get areDefault() {
    return isEqual(this.selectedNames, defaultColumnNames);
  }

  get selectedNames() {
    return this.selected?.mapBy('name');
  }

  withSelected(columns) {
    const isSelected = (column) =>
      this.selectedNames.any((selectedName) => selectedName === column?.name);
    return columns.map((column) => ({
      ...column,
      selected: isSelected(column),
    }));
  }

  @action
  select(column) {
    column.selected
      ? this.remove(column.name)
      : this.switch(column.name, this.selected.length);
  }

  @action
  dynamicSelect(column) {
    if (column.selected) {
      this.selected = this.selected.rejectBy('name', column.name);
    } else {
      const col = this.allColumns.findBy('name', column.name);
      if (!col) return;

      if (this.selected.length >= SELECTED_COLUMNS_MAX) {
        this.notifications.remove(this.notification);
        this.notification = this.notifications.error(
          `Maximum number of columns (${SELECTED_COLUMNS_MAX}) selected.`,
          { autoClear: true }
        );
        return;
      }

      const columns = this.selected.slice();
      columns.splice(this.selected.length, 1, col);
      this.selected = columns;
    }
  }

  @action
  switch(columnName, index) {
    const column = this.allColumns.findBy('name', columnName);
    if (!column) return;

    if (this.selected.length >= SELECTED_COLUMNS_MAX) {
      this.notifications.remove(this.notification);
      this.notification = this.notifications.error(
        `Maximum number of columns (${SELECTED_COLUMNS_MAX}) selected.`,
        { autoClear: true }
      );
      return;
    }

    const columns = this.selected.slice();
    columns.splice(index, 1, column);
    this.selected = columns;
    this.saveColumnNames(this.selectedNames);
  }

  @action
  drop(bottomColumn, topColumn) {
    const columns = this.selected.slice();
    const bottomIndex = columns.indexOf(bottomColumn);
    const topIndex = columns.indexOf(topColumn);
    columns.splice(bottomIndex, 1, topColumn);
    columns.splice(topIndex, 1, bottomColumn);
    this.selected = columns;
    this.saveColumnNames(this.selectedNames);
  }

  @action
  remove(columnName) {
    this.selected = this.selected.rejectBy('name', columnName);
    this.saveColumnNames(this.selectedNames);
  }

  @action
  revert() {
    if (this.areDefault) return;

    this.selected = defaultColumnNames.map((columnName) =>
      this.allColumns.findBy('name', columnName)
    );
    this.saveColumnNames(this.selectedNames);
  }
}
