import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { debounce, later } from '@ember/runloop';
import { isBlank } from '@ember/utils';
import { action, set } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import ENV from 'nightwatch-web/config/environment';
import sum from 'lodash-es/sum';
import { differenceInDays, format, isSameDay, startOfDay, sub } from 'date-fns';
import { relativeDateRanges } from 'nightwatch-web/constants/date-ranges';
import {
  blocksForCompetitorTemplate,
  blocksForProgressTemplate,
} from 'nightwatch-web/utils/report-block-composer';
import downloadFile from '../../utils/downloadFile';

const availableReportColumns = [
  {
    name: 'keyword-position',
    displayName: 'Rank',
    sortProperty: 'position',
  },
  {
    name: 'keyword-position-organic',
    displayName: 'Organic rank',
    sortProperty: 'position_organic',
  },
  {
    name: 'keyword-position-places-image',
    displayName: 'Image carousel rank',
    sortProperty: 'position_places_image',
  },
  {
    name: 'keyword-position-local-pack',
    displayName: 'Local pack rank',
    sortProperty: 'position_local_pack',
  },
  {
    name: 'keyword-position-previous',
    displayName: 'Previous Rank',
    sortProperty: 'previous_position',
  },
  {
    name: 'keyword-position-max',
    displayName: 'Best Rank',
    sortProperty: 'best_position',
  },
  {
    name: 'keyword-created-at',
    displayName: 'Added On',
    sortProperty: 'created_at',
  },
  {
    name: 'keyword-last-update',
    displayName: 'Last Update',
    sortProperty: 'last_processed_at',
  },
  {
    name: 'keyword-last-position-change',
    displayName: 'Last Change',
    sortProperty: 'position_changed_by',
  },
  {
    name: 'keyword-mini-graph',
    displayName: 'Evolution',
    sortProperty: 'position_changed_during_last_day',
  },
  {
    name: 'keyword-search-engine',
    displayName: 'Search Engine',
    sortProperty: 'engine',
  },
  {
    name: 'keyword-location',
    displayName: 'Location',
    sortProperty: 'google_gl',
  },
  {
    name: 'keyword-language',
    displayName: 'Language',
    sortProperty: 'google_hl',
  },
  {
    name: 'keyword-dwm-change',
    displayName: 'D/W/M Change',
    sortProperty: 'position_changed_during_last_day',
  },
  {
    name: 'position-changed-during-last-day',
    displayName: 'Daily change',
    sortProperty: 'position_changed_during_last_day',
  },
  {
    name: 'position-changed-during-last-week',
    displayName: 'Weekly change',
    sortProperty: 'position_changed_during_last_week',
  },
  {
    name: 'position-changed-during-last-month',
    displayName: 'Monthly change',
    sortProperty: 'position_changed_during_last_month',
  },
  {
    name: 'keyword-results-count',
    displayName: 'Results #',
    sortProperty: 'results_count',
  },
  {
    name: 'keyword-sv-local',
    headerName: 'SV (local)',
    displayName: 'Search Volume (local)',
    sortProperty: 'adwords_local_search_volume',
  },
  {
    name: 'keyword-sv-global',
    headerName: 'SV (global)',
    displayName: 'Search Volume (global)',
    sortProperty: 'adwords_global_search_volume',
  },
  {
    name: 'keyword-cpc-local',
    headerName: 'CPC (local)',
    displayName: 'Average Cost per Click (local)',
    sortProperty: 'adwords_local_average_cpc',
  },
  {
    name: 'keyword-cpc-global',
    headerName: 'CPC (global)',
    displayName: 'Average Cost per Click (global)',
    sortProperty: 'adwords_global_average_cpc',
  },
  {
    name: 'keyword-url',
    displayName: 'URL',
    sortProperty: 'url',
  },
  {
    name: 'keyword-clicks',
    displayName: 'Daily Clicks',
    sortProperty: 'clicks',
  },
  {
    name: 'keyword-impressions',
    displayName: 'Daily Impressions',
    sortProperty: 'impressions',
  },
  {
    name: 'keyword-ctr',
    displayName: 'Daily CTR',
    sortProperty: 'ctr',
  },
  {
    name: 'keyword-search-console-position',
    displayName: 'Search Console Rank',
    sortProperty: 'search_console_position',
  },
  {
    name: 'keyword-start-position',
    displayName: 'Start Rank',
    sortProperty: 'start_position',
  },
  {
    name: 'keyword-end-position',
    displayName: 'End Rank',
    sortProperty: 'end_position',
  },
  {
    name: 'keyword-position-change',
    displayName: 'Rank Change',
    sortProperty: 'position_change',
  },
];

const initialScheduleTimes = {
  start_time: null,
  end_time: null,
};

export default class ReportFormComponent extends Component {
  @service session;
  @service siteData;
  @service metrics;
  @service notifications;
  @service('reports/report-utils') reportUtils;
  @tracked isSaving = false;
  @tracked previewUrl = null;
  @tracked blocksError = null;
  @tracked dateRangeError = null;
  @tracked recipientsError = null;
  @tracked blocks = null;

  blocksLimit = 8;
  keywordReportLimit = 10000;

  get backlinkViews() {
    return this.args.url?.backlinkViews;
  }

  get siteAuditViews() {
    return this.args.url?.siteAuditViews;
  }

  get competitors() {
    return this.args.url?.competitors;
  }

  get isGlobalViewReport() {
    return isBlank(this.args.url);
  }

  get dynamicViews() {
    if (this.isGlobalViewReport) return [this.args.dynamicView];
    return this.args.url.dynamicViews.filter((dv) => !dv.isNew);
  }

  get templates() {
    const templates = this.reportUtils.templates;
    if (this.isGlobalViewReport) {
      return templates.filter((t) =>
        ['basic_report', 'progress_report'].includes(t.name)
      );
    }
    return templates;
  }

  get siteAuditEnabled() {
    return (
      this.args.url?.lastCrawlingSession?.isDone &&
      this.session.user?.siteAuditEnabled
    );
  }

  get backlinksVisible() {
    return this.session.user.backlinksVisible;
  }

  get availableBlocks() {
    return this.reportUtils.allBlocks.filter((block) => {
      if (block.name.match(/^site_audit/)) return this.siteAuditEnabled;
      if (block.name.match(/^backlink/)) return this.backlinksVisible;
      return true;
    });
  }

  get availableGroupings() {
    return this.reportUtils.availableGroupings;
  }

  get graphs() {
    return this.args.url?.graphs.rejectBy('isNew');
  }

  get availableLogos() {
    const logos = this.reportUtils.prepareLogos(this.args.logos);
    return this.siteData.isWhiteLabel
      ? logos.filter(
          (logo) => logo.imgSrc !== '/assets/images/nightwatch-report-logo.png'
        )
      : logos;
  }

  get totalReportKeywordCountForKeywordLists() {
    return sum(
      this.blocks
        .filter((b) => b.name === 'keyword_list')
        .mapBy('_keywordCount')
        .compact()
    );
  }

  get columnsForDropdown() {
    const availableColumns = availableReportColumns.map((c) => {
      return { id: c.name, text: c.displayName };
    });

    const url = this.args.url;
    if (url && url.competitors) {
      const availableCompetitorColumns = this.extractAvailableCompetitorColumns(
        url.competitors
      );
      return availableColumns.concat(availableCompetitorColumns);
    } else {
      return availableColumns;
    }
  }

  constructor() {
    super(...arguments);
    const report = this.args.report;
    const blocks = report.report_config?.blocks;
    let template = report.template_name;
    report.report_format = report.report_format || 'pdf';
    report.template_name = template || 'basic_report';

    // Set blocks
    if (blocks) {
      this.blocks = blocks;
      // Prepare template-reactive columns structure
      const keywordListBlocks = blocks.filter((b) => b.name === 'keyword_list');
      keywordListBlocks.forEach((block) => {
        const columns = block.columns;
        block.selectedColumns = columns.map((c) => Object.create({ name: c }));
      });
    } else {
      template = template || 'basic_report';
      this.blocks = this.blocksFor(template);
    }
    this.initDateRange();
  }

  @action
  didInsert() {
    this.refreshPreview();
  }

  @action
  initDateRange() {
    const report = this.args.report;
    if (report.date_range_type) {
      const range = relativeDateRanges().find(
        (r) => r.type === report.date_range_type
      );
      if (!range) return;
      report.start_time = new Date(range.start);
      report.end_time = new Date(range.end);
    }
  }

  @task
  *downloadReport() {
    let { url, reportFormat } = this.generateReportUrl();
    yield fetch(url).then(async (e) => {
      const file = await e.blob();
      const name = this.args.url
        ? this.args.url.domain.replace('.', '-')
        : this.args.dynamicView?.name;
      downloadFile(
        URL.createObjectURL(file),
        `report-for-${name}.${reportFormat}`
      );
    });
  }

  extractAvailableCompetitorColumns(competitors) {
    return this.reportUtils.extractAvailableCompetitorColumns(competitors);
  }

  setDefaultProperties(block) {
    const defaultEndDate = new Date();
    const defaultStartDate = new Date(defaultEndDate);
    defaultStartDate.setDate(defaultEndDate.getDate() - 7);

    switch (block.name) {
      case 'keyword_overview':
        return {
          name: 'keyword_overview',
          url_id: this.args.url?.id,
          view_id: this.args.dynamicView?.id,
        };
      case 'keyword_list':
        return {
          name: 'keyword_list',
          url_id: this.args.url?.id,
          view_id: this.args.dynamicView?.id,
          columns: this.reportUtils.defaultColumns,
          selectedColumns: this.reportUtils.defaultColumns.map((c) =>
            Object.create({ name: c })
          ),
          sort: 'keyword-position',
          sort_direction: 'asc',
          include_results: false,
          include_competitors_results: false,
          grouping: this.reportUtils.defaultGrouping,
        };
      case 'graph':
        return { name: 'graph', graph_id: this.graphs.firstObject?.id };
      case 'traffic_overview':
        return { name: 'traffic_overview', url_id: this.args.url?.id };
      case 'backlink_overview':
        return { name: 'backlink_overview', url_id: this.args.url?.id };
      case 'site_audit_overview':
        return { name: 'site_audit_overview', url_id: this.args.url?.id };
      case 'site_audit_pages':
        return { name: 'site_audit_pages', url_id: this.args.url?.id };
      case 'compare_periods':
        return {
          name: 'compare_periods',
          url_id: this.args.url?.id,
          view_id: this.args.dynamicView?.id,
          include_keywords: true,
          first_period: {
            start_date: defaultStartDate.toISOString().split('T')[0],
            end_date: defaultEndDate.toISOString().split('T')[0],
          },
          second_period: {
            start_date: defaultStartDate.toISOString().split('T')[0],
            end_date: defaultEndDate.toISOString().split('T')[0],
          },
        };
      default:
        return block;
    }
  }

  get reportParams() {
    const dateFrom = this.args.report?.start_time;
    const dateTo = this.args.report?.end_time;
    const blocks = this.blocks;
    const name = this.args.report?.name;
    const whitelabelLogoId = this.args.report?.whitelabel_logo_id;
    const formatting = this.args.report?.report_format;
    const useChromeRenderer =
      this.args.report?.report_config?.use_chrome_renderer;

    return {
      date_from: format(new Date(dateFrom), 'yyyy-MM-dd'),
      date_to: format(new Date(dateTo), 'yyyy-MM-dd'),
      blocks: blocks,
      whitelabel_logo_id: whitelabelLogoId,
      name: name,
      format: formatting,
      use_chrome_renderer: !!useChromeRenderer,
    };
  }

  generateReportUrl(preview = false) {
    const reportParams = this.reportParams;
    const {
      date_from,
      date_to,
      blocks,
      whitelabel_logo_id,
      name,
      use_chrome_renderer,
    } = reportParams;

    let reportFormat = reportParams.format;

    if (preview && reportFormat === 'pdf') {
      reportFormat = 'html';
    }

    const accessToken = this.session.token;

    let url = `${
      ENV.apiTaskBaseURL
    }report_creator/generate.${reportFormat}?access_token=${accessToken}&date_from=${date_from}&date_to=${date_to}&blocks=${JSON.stringify(
      blocks
    )}&whitelabel_logo_id=${whitelabel_logo_id}&name=${name}&use_chrome_renderer=${use_chrome_renderer}`;

    if (preview) {
      url = `${url}&preview=true`;
    }

    return { url, reportFormat };
  }

  blocksFor(template) {
    const { url, dynamicView } = this.args;

    if (template === 'basic_report') {
      return [{ name: 'keyword_overview' }, { name: 'keyword_list' }].map((b) =>
        this.setDefaultProperties(b)
      );
    } else if (template === 'competitor_report') {
      const graphs = this.graphs;
      const competitors = this.competitors;
      return blocksForCompetitorTemplate({ url, graphs, competitors });
    } else if (template === 'progress_report') {
      return blocksForProgressTemplate({
        url,
        dynamicView,
      });
    }
  }

  handleScheduledData(report) {
    report.report_interval = this.reportUtils.getReportInterval(report);
  }

  handleRecipients() {
    const recipients = Array.from(
      document.querySelectorAll('.recipient-input')
    ).map((e) => e.value);

    if (recipients.filter((r) => r).length > 0) {
      this.args.report.recipients = recipients;
    }
  }

  validateRecipients() {
    if (!this.args.report.scheduled) {
      return true;
    }
    let recipientsValid = true;
    if (this.args.report.recipients.length === 0) {
      recipientsValid = false;
    } else {
      this.args.report.recipients.forEach((recipient) => {
        if (
          recipient.length < 5 ||
          recipient.indexOf('@') === -1 ||
          recipient.indexOf('.') === -1
        ) {
          return (recipientsValid = false);
        }
      });
    }

    if (recipientsValid) {
      this.recipientsError = null;
    } else {
      this.recipientsError = 'One or more recipient e-mails are invalid.';
    }

    return recipientsValid;
  }

  validateDateRange() {
    if (!this.args.report.scheduled) {
      return true;
    }
    if (!this.isLastDayOrWeekOrMonth) {
      this.dateRangeError =
        'Select Yesterday, Last 7 days or Last 30 days when saving a scheduled report.';
      return false;
    } else {
      return true;
    }
  }

  validateBlocks() {
    if (this.blocks.length === 0) {
      this.blocksError =
        'You need to add at least one element for your report.';
      return false;
    }
    return true;
  }

  get isLastDayOrWeekOrMonth() {
    const start = new Date(this.args.report.start_time);
    const end = new Date(this.args.report.end_time);
    const yesterday = sub(new Date(), { days: 1 });
    const endIsYesterday = isSameDay(end, yesterday);
    if (endIsYesterday) {
      const daysDiff = Math.abs(
        differenceInDays(startOfDay(new Date(start)), new Date(end))
      );
      return [1, 7, 30].includes(daysDiff);
    } else {
      return false;
    }
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this.reportUtils.toggleKeywordLimitNotification(false);
    this.reportUtils.toggleHighKeywordCountWarningNotification(false);
  }

  @action
  setDateRange({ type, start, end }) {
    this.dateRangeError = null;
    this.args.report.date_range_type = type;
    this.args.report.start_time = new Date(start);
    this.args.report.end_time = new Date(end);
    this.refreshPreview();
  }

  @action
  addReportBlock(block) {
    this.blocksError = null;
    if (this.blocks.length >= this.blocksLimit) {
      this.notifications.error(
        `Maximum amount of elements for a report is ${this.blocksLimit}. If you need more elements on a report, create an additional report.`,
        { autoClear: true }
      );
      return;
    }

    block = this.setDefaultProperties(block);
    this.blocks.pushObject(block);
    this.refreshPreview();
  }

  @action
  toggleIncludeResults() {
    this.refreshPreview();
  }

  @action
  toggleIncludeCompetitorsResults(block) {
    block.include_competitors_results = !block.include_competitors_results;
    this.refreshPreview();
  }

  @action
  toggleKeywordComparisonDateChange() {
    this.refreshPreview();
  }

  @action
  setSelectedTemplate(template) {
    if (this.args.report.template_name === template) {
      return;
    }
    this.args.report.template_name = template;
    this.blocks = this.blocksFor(template);
    this.refreshPreview();
  }

  @action
  removeBlock(block) {
    this.blocks.removeObject(block);
    this.refreshPreview();
  }

  @action
  selectedColumnsChanged(block, columns) {
    block.columns = columns;
    this.refreshPreview();
  }

  @action
  onNameChange(name) {
    this.args.report.name = name;
    debounce(this, 'refreshPreview', 400);
  }

  @action
  onSortChange(block, sort, sortDirection) {
    set(block, 'sort', sort);
    set(block, 'sort_direction', sortDirection);
    this.refreshPreview();
  }

  @action
  onGroupingChange(block, grouping) {
    block.grouping = grouping;
    this.refreshPreview();
  }

  @action
  refreshPreview() {
    this.previewUrl = null;
    later(() => {
      const previewUrl = this.generateReportUrl(true);
      this.previewUrl = previewUrl.url;
      // TODO: Test the below logic, it used to be in the observer above
      //  but might not be the best place for this logic.
      const count = this.totalReportKeywordCountForKeywordLists;
      this.reportUtils.toggleHighKeywordCountWarningNotification(count);
      this.reportUtils.toggleKeywordLimitNotification(
        count,
        this.keywordReportLimit
      );
    }, 100);
  }

  storeAndClearReportScheduleTimes(report) {
    initialScheduleTimes.start_time = report.start_time;
    initialScheduleTimes.end_time = report.end_time;
    report.start_time = null;
    report.end_time = null;
  }

  restoreReportScheduleTimes(report) {
    if (!initialScheduleTimes.start_time || !initialScheduleTimes.end_time) {
      return;
    }
    report.start_time = initialScheduleTimes.start_time;
    report.end_time = initialScheduleTimes.end_time;
    initialScheduleTimes.start_time = report.start_time;
    initialScheduleTimes.end_time = report.end_time;
  }

  @action
  save() {
    this.dateRangeError = null;
    this.recipientsError = null;
    this.blocksError = null;
    const report = this.args.report;
    const blocks = this.blocks;
    const reportConfig = { blocks };
    this.handleScheduledData(report);
    this.handleRecipients(report);

    const recipientsValid = this.validateRecipients();
    const dateRangeValid = this.validateDateRange();
    const blocksValid = this.validateBlocks();

    // According to validate_date_ranges on the API, start_time and
    // end_time must not be present if a report is already scheduled.
    // So we store the times and clear them before saving, and restore
    // them after saving.
    if (this.args.report.scheduled) {
      this.storeAndClearReportScheduleTimes(report);
    }

    if (!recipientsValid || !dateRangeValid || !blocksValid) {
      this.notifications.error('There were errors while saving the report.', {
        autoClear: true,
      });
      return;
    }

    if (blocks.length > this.blocksLimit) {
      this.notifications.error(
        `Maximum amount of elements for a report is ${this.blocksLimit}. If you need more elements on a report, create an additional report.`,
        { autoClear: true }
      );
      return;
    }

    const totalReportKeywordCountForKeywordLists =
      this.totalReportKeywordCountForKeywordLists;
    this.reportUtils.toggleKeywordLimitNotification(
      totalReportKeywordCountForKeywordLists,
      this.keywordReportLimit
    );
    if (totalReportKeywordCountForKeywordLists >= this.keywordReportLimit) {
      return;
    }

    report.report_version = 2;
    report.report_config = reportConfig;
    const promise = report.save();

    this.isSaving = true;
    promise.then(() => {
      this.notifications.success('Report successfully saved.', {
        autoClear: true,
      });
      this.args.onReportSave?.(report);
      this.metrics.trackEvent({ event: 'Create Report' });
    });
    promise.catch(() => {
      this.notifications.error('There were errors while saving the report.', {
        autoClear: true,
      });
    });
    promise.finally(() => {
      this.isSaving = false;
      if (this.args.report.scheduled) {
        this.restoreReportScheduleTimes(report);
      }
    });
  }
}
