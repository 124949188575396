import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import ApplicationSerializer from 'nightwatch-web/serializers/application';

export default class SerpPreviewSerializer extends ApplicationSerializer.extend(
  EmbeddedRecordsMixin
) {
  primaryKey = '_id';
  attrs = {
    organic: { deserialize: 'records' },
    localPack: { deserialize: 'records' },
    knowledgePanel: { deserialize: 'records' },
    featuredSnippet: { deserialize: 'records' },
    carousel: { deserialize: 'records' },
  };

  normalize(modelClass, hash, ...rest) {
    [
      'organic',
      'local_pack',
      'knowledge_panel',
      'featured_snippet',
      'carousel',
    ].forEach((key) => this.normalizeResultsPayload(key, hash));

    delete hash.serp?.results;
    hash = { ...hash, ...hash.serp };
    delete hash.serp;
    return super.normalize(modelClass, hash, ...rest);
  }

  normalizeResultsPayload(key, hash) {
    const results = hash.serp?.results || {};

    hash[key] = (results[key] || [])
      .filter((result) => !!result)
      .map((result, index) => {
        result.id = `${hash._id}_${index}`;
        return result;
      });
  }
}
