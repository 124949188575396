import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { action } from '@ember/object';

/**
 @argument filter
 @argument onFilterChange
 */
export default class NwKeywordTableFilterItem extends Component {
  @service filterData;

  get isPresentAbsentCondition() {
    return ['present', 'absent'].includes(this.args.filter.condition);
  }

  get isDateField() {
    return this.fieldType.name === 'date';
  }

  get isSelectField() {
    return this.fieldType.name === 'select';
  }

  get showValueInput() {
    return !this.args.filter.isBooleanField && !this.isPresentAbsentCondition;
  }

  get fieldType() {
    const field_data = this.filterData.allFilterFields.findBy(
      'field',
      this.args.filter.field
    ) ?? { data_type: '' };

    return {
      name: field_data.data_type.replace('_with_presence', ''),
      presence: field_data.data_type.includes('with_presence'),
    };
  }

  get currentConditions() {
    const field = this.args.filter?.field;

    if (!field) {
      return this.filterData.stringConditions;
    }

    const type = this.fieldType;

    if (type.name === 'boolean') {
      // boolean types are handled differently in the template and they don't
      // use the `currentConditions` property, however it was used by the
      // fieldChanged callback that resets the filter condition, so we set it to an empty
      // array
      return [];
    }

    if (type.name === 'select') {
      return this.filterData.exactConditions;
    }

    let conditions;
    if (type.name === 'number' || type.name === 'date') {
      conditions = this.filterData.numberAndDateConditions;
    } else {
      conditions = this.filterData[`${type.name}Conditions`];
    }

    if (type.presence) {
      conditions = conditions.concat(this.filterData.presentAbsentConditions);
    }

    return conditions;
  }

  @action
  fieldChanged() {
    // The idea behind this operation is to reset the filter condition and value
    // in case the newly selected field type does not supports the currently
    // selected condition, if any. There's an special case with boolean fields,
    // since they clear the condition when selected, and set the value by
    // default to `false`, so these are handled separately. By doing this, we
    // prevent sending invalid requests to the API.

    const { filter } = this.args;

    const condition = filter.condition;

    if (!condition) {
      const type = this.fieldType.name;
      const value = filter.value;

      // boolean types clear the filter condition but set the filter value,
      // detect these cases and clear the value if needed
      if ((type !== 'boolean' && value === 'true') || value === 'false')
        filter.value = '';

      return;
    }

    if (!this.currentConditions.findBy('condition', condition)) {
      filter.condition = '';
      filter.value = '';
    }

    this.args.onFilterChange();
  }

  @action
  conditionChanged() {
    this.args.onFilterChange();
  }

  @action
  valueChanged() {
    this.args.filter.valueError = null;
    this.args.onFilterChange();
  }
}
