import Model, { hasMany, attr } from '@ember-data/model';

export const ENGINE = {
  GOOGLE: 'google',
  GOOGLE_PLACES: 'google_places',
};

export default class SerpPreview extends Model {
  // fields
  @attr('number') version;
  @attr('number') keywordId;
  @attr('string') date; // Format: yyyy-MM-dd
  @attr('string') query;
  @attr('string') engine;
  @attr('string') country;
  @attr('string') language;
  @attr('string') location;
  @attr('number') resultsTotal;
  @attr() urlMatches;

  // results
  @hasMany('serp-result-organic') organic;
  @hasMany('serp-result-local') localPack;
  @hasMany('serp-result-carousel') carousel;
  @hasMany('serp-result-knowledge-panel') knowledgePanel;
  @hasMany('serp-result-featured') featuredSnippet;

  get hasOrganic() {
    return this.organic.length > 0;
  }
  get hasLocalPack() {
    return this.localPack.length > 0;
  }
  get hasCarousel() {
    return this.carousel.length > 0;
  }
  get hasKnowledgePanel() {
    return this.knowledgePanel.length > 0;
  }
  get hasFeaturedSnippet() {
    return this.featuredSnippet.length > 0;
  }
  get isGoogle() {
    return this.engine === ENGINE.GOOGLE;
  }
  get isGooglePlaces() {
    return this.engine === ENGINE.GOOGLE_PLACES;
  }
}
