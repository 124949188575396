import Component from '@glimmer/component';

export const TYPE = {
  FEATURED: 'featured_snippet',
  LOCAL_PACK: 'local_pack',
  IMAGE: 'places_image',
  KNOWLEDGE: 'knowledge_panel',
};
export const TITLE = {
  [TYPE.FEATURED]: 'Featured Snippet',
  [TYPE.LOCAL_PACK]: 'Local Pack',
  [TYPE.IMAGE]: 'Image Carousel',
  [TYPE.KNOWLEDGE]: 'Knowledge Panel',
};
export const ICON = {
  [TYPE.FEATURED]: 'icon-quote-right',
  [TYPE.LOCAL_PACK]: 'icon-list',
  [TYPE.IMAGE]: 'icon-picture',
  [TYPE.KNOWLEDGE]: 'icon-knowledge-panel',
};
export const MODIFIER = {
  RANKING: 'ranking',
  AVAILABLE: 'available',
  UNAVAILABLE: 'unavailable',
};

export default class SerpOpportunityComponent extends Component {
  get isAvailable() {
    return this.args.keyword.available_serp_features[this.args.type] ?? false;
  }

  get isRanking() {
    return Boolean(this.args.keyword[`position_${this.args.type}`]);
  }

  get icon() {
    return ICON[this.args.type];
  }

  get title() {
    return TITLE[this.args.type];
  }

  get tooltip() {
    const { title, isAvailable, isRanking } = this;
    let tooltip = `${title} ${isAvailable ? 'available' : 'unavailable'}`;
    if (isAvailable) {
      tooltip += `, keyword ${isRanking ? 'is' : ' not'} ranking`;
    }
    return tooltip;
  }

  get classModifier() {
    const { isAvailable, isRanking } = this;
    if (isRanking) return MODIFIER.RANKING;

    return isAvailable ? MODIFIER.AVAILABLE : MODIFIER.UNAVAILABLE;
  }
}
