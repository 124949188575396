import Model, { belongsTo, hasMany } from '@ember-data/model';
import { tracked } from '@glimmer/tracking';

export default class FilterGroup extends Model {
  @belongsTo('dynamic-view', { async: false }) dynamicView;
  @hasMany('filter', { embedded: 'always', async: false }) filters;

  get visibleFilters() {
    return this.filters.rejectBy('markedAsDeleted');
  }

  async save() {
    await super.save();
    /*
      Unload all unsaved filters after the group has finished saving.

      Otherwise upon creating a new view the original (unsaved) filters
      are still in filterGroup along with the new saved ones. This means the
      filtering UI shows duplicate filters.

      Believe this is because tracking of dirty/saved model objects doesn't work
      perfectly with EmbeddedRecordsMixin.
     */
    this.filters.filterBy('isNew').invoke('unloadRecord');
    return this;
  }

  @tracked markedAsDeleted = false;
}
