import { all, task, timeout } from 'ember-concurrency';
import { groupMethods } from '../grouping';
import Dimension from './dimension';
import { DimensionGroup } from './dimension-group';
import { DimensionSubgroup } from './dimension-subgroup';
import dimensionSources from './dimension-sources';
import { toUpperCase } from '../../../helpers/to-upper-case';
import POSITION_VALUES from '../../../constants/chart/position-values';
import KeywordsResource from '../../../resources/keywords';
import { getOwner } from '@ember/application';
import { tracked } from '@glimmer/tracking';

class KeywordDimension extends Dimension {
  requestWith = ['keyword'];

  get findData() {
    return (
      this.providedData
        ?.filterBy('url_id', parseInt(this.url.id, 10))
        ?.find((d) => d.keyword_id === parseInt(this.keyword.id, 10))
        ?.keyword_series?.findBy('name', this.name)?.series ?? []
    );
  }
}

class KeywordCompetitorDimension extends KeywordDimension {
  get findData() {
    return (
      this.providedData
        ?.filterBy('url_id', parseInt(this.url.id, 10))
        ?.find((d) => d.keyword_id === parseInt(this.keyword.id, 10))
        ?.competitor_series?.find(
          (d) =>
            d.competitor_id === parseInt(this.competitor.id) &&
            d.name === this.name
        )?.series ?? []
    );
  }

  get series() {
    return this.graph.series.find(
      (s) =>
        s.name === this.name &&
        s.url === this.url &&
        s.competitor === this.competitor &&
        s.keyword === this.keyword
    );
  }
}

export class KeywordDimensions extends DimensionGroup {
  label = 'Keywords';
  @tracked dimensions = [];
  @tracked subgroups = [];
  @tracked allKeywordsLoaded = false;
  currentPage = 1;

  resetProperties() {
    this.dimensions = [];
    this.subgroups = [];
    this.allKeywordsLoaded = false;
    this.currentPage = 1;
  }

  @task({ restartable: true })
  *keywordSearch(query) {
    this.resetProperties();
    yield timeout(250);
    this.loadTask.perform(query);
  }

  @task
  *partialLoadTask(seriesModels) {
    const keywordIds = seriesModels
      .map((s) => s.belongsTo('keyword').id())
      .uniq()
      .compact();

    const [, ...keywords] = yield all([
      this.url.loadCompetitors.perform(),
      ...keywordIds.map((id) => this.maybeFindModel.perform('keyword', id)),
    ]);

    this.createSubgroups(keywords.compact());
  }

  @task({ drop: true })
  *loadTask(searchQuery = '') {
    if (this.allKeywordsLoaded) return;
    const params = {
      urlId: this.url.id,
      page: this.currentPage,
      search: searchQuery,
      sort: 'position',
      direction: 'desc',
      limit: 20,
    };
    const keywordsResource = new KeywordsResource({
      owner: getOwner(this),
    });
    const [keywords] = yield all([
      keywordsResource.load.perform(params),
      this.url.loadCompetitors.perform(),
    ]);
    if (keywords.length === 0) this.allKeywordsLoaded = true;
    this.createSubgroups(keywords);
  }

  createSubgroups(keywords) {
    const { competitors } = this.url;
    let keywordArray = keywords.map((keyword) => {
      const urlLabel = `${keyword.url?.friendlyUrl || this.url.friendlyUrl}`;
      const props = {
        ...this.dimensionProps,
        keyword,
        subTitle: `${keyword.query} (${urlLabel})`,
        mobile: keyword.mobile,
        googleGl: keyword.google_gl,
        // Source data contains gaps for any days we didn't crawl
        // Ensures gaps get filled with a value representing this
        fillGapsValue: POSITION_VALUES.NOT_CRAWLED,
      };
      const locationLabel = keyword.local_search
        ? keyword.fullLocationShortened
        : toUpperCase([keyword.google_gl]);
      const queryLabel = `${keyword.query} (${
        keyword.mobile ? '📱' : '🖥'
      } ${locationLabel})`;
      return new DimensionSubgroup({
        label: keyword.query,
        keyword,
        dimensions: [
          new KeywordDimension({
            ...props,
            name: 'position',
            scaleId: 'nwPosition',
            title: 'Position',
            label: `Position | ${queryLabel} | ${urlLabel}`,
            onSeriesLoaded: this.onSeriesLoaded,
            previewKeyword: keyword, // Enables clickable points to open SERP preview
            competitorDimensions: competitors.map(
              (competitor) =>
                new KeywordCompetitorDimension({
                  ...props,
                  competitor,
                  name: 'position',
                  scaleId: 'nwPosition',
                  title: 'Position',
                  subTitle: `${keyword.query} (${competitor.competitor_url})`,
                  label: `Position | ${queryLabel} | ${competitor.competitor_url}`,
                })
            ),
          }),
          new KeywordDimension({
            ...props,
            name: 'position_organic',
            scaleId: 'nwPosition',
            title: 'Position Organic',
            label: `Position Organic | ${queryLabel} | ${urlLabel}`,
          }),
          new KeywordDimension({
            ...props,
            name: 'position_local_pack',
            scaleId: 'nwPosition',
            title: 'Position Local Pack',
            label: `Position Local Pack | ${queryLabel} | ${urlLabel}`,
          }),
          new KeywordDimension({
            ...props,
            name: 'position_places',
            scaleId: 'nwPosition',
            title: 'Position Places',
            label: `Position Places | ${queryLabel} | ${urlLabel}`,
          }),
          new KeywordDimension({
            ...props,
            name: 'position_places_image',
            scaleId: 'nwPosition',
            title: 'Position Places Image',
            label: `Position Places Image | ${queryLabel} | ${urlLabel}`,
          }),
          new KeywordDimension({
            ...props,
            name: 'clicks',
            title: 'Clicks',
            label: `Clicks | ${queryLabel} | ${urlLabel}`,
            groupMethod: groupMethods.sum,
            source: dimensionSources.searchConsole,
          }),
          new KeywordDimension({
            ...props,
            name: 'impressions',
            title: 'Impressions',
            label: `Impressions | ${queryLabel} | ${urlLabel}`,
            groupMethod: groupMethods.sum,
            source: dimensionSources.searchConsole,
          }),
          new KeywordDimension({
            ...props,
            name: 'search_console_position',
            title: 'Search Console Position',
            label: `Search Console Position | ${queryLabel}| | ${urlLabel}`,
            scaleId: 'nwPosition',
            source: dimensionSources.searchConsole,
          }),
          new KeywordDimension({
            ...props,
            name: 'ctr',
            title: 'CTR',
            label: `CTR | ${queryLabel} | ${urlLabel}`,
            source: dimensionSources.searchConsole,
          }),
        ],
      });
    });
    const arrayBuffer = [];
    arrayBuffer.push(...keywordArray);
    this.subgroups = [...this.subgroups, ...arrayBuffer];

    const dimensions = this.subgroups.flatMap((s) => s.dimensions);
    const competitorDimensions = dimensions
      .flatMap((d) => d.competitorDimensions)
      .compact();
    this.dimensions = dimensions.concat(competitorDimensions);
    this.currentPage++;
  }
}
