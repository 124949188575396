import Service, { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { format } from 'date-fns';
import { all, task } from 'ember-concurrency';

export default class FilterGroupHandlingService extends Service {
  @service store;
  @service router;
  @service metrics;
  @service dashboardState;
  @service filterData;

  validateView(dynamicView) {
    const name = dynamicView.name;
    dynamicView.nameError = null;
    if (!!name && name.trim().length > 0) {
      return true;
    } else {
      return (dynamicView.dynamicView = 'Name missing.');
    }
  }

  validateFilters(dynamicView, displayErrors = true) {
    let filtersValid = true;

    dynamicView.visibleFilterGroups.forEach((filterGroup) => {
      const filters = filterGroup.filters;
      if (!(filters.length > 0)) {
        filtersValid = false;
      }
      return filters.forEach((filter) => {
        const filterValid = this.validateFilter(filter, displayErrors);
        if (!filterValid) {
          return (filtersValid = false);
        }
      });
    });

    dynamicView.filtersValid = filtersValid;
    return filtersValid;
  }

  resetFilterErrors(filter) {
    filter.valueError = null;
    filter.conditionError = null;
    filter.fieldError = null;
  }

  validateFilter(filter, displayErrors) {
    this.resetFilterErrors(filter);
    let value = filter.value != null ? String(filter.value).trim() : '';
    const condition = filter.condition;
    const field = filter.field;

    const isNum = (val) => !isNaN(parseFloat(val)) && isFinite(val);

    const getFieldInfo = (field) => {
      return this.filterData.allFilterFields.findBy('field', field);
    };

    let filterValid = true;

    if (filter.isBooleanField) {
      if (!['true', 'false'].includes(String(filter.value))) {
        filter.booleanValue = false;
      }
      return true;
    }

    if (!value || !condition || !field) {
      if (!value) {
        if (!['present', 'absent'].includes(condition)) {
          filterValid = false;
          if (displayErrors) {
            filter.valueError = 'Value missing.';
          }
        }
      }
      if (!condition) {
        filterValid = false;
        if (displayErrors) {
          filter.conditionError = 'Condition missing.';
        }
      }
      if (!field) {
        filterValid = false;
        if (displayErrors) {
          filter.fieldError = 'Field missing.';
        }
      }
    } else {
      const { data_type } = getFieldInfo(field);
      if (data_type.includes('number')) {
        if (!isNum(value)) {
          filterValid = false;
          if (displayErrors) {
            filter.valueError = 'Value should be a number.';
          }
        }
      } else if (data_type.includes('date')) {
        value = format(new Date(value), 'MM/dd/yyyy');
        if (!format(new Date(value), 'MM/dd/yyyy')) {
          filterValid = false;
          if (displayErrors) {
            filter.valueError = 'Value should be a date.';
          }
        } else {
          filter.value = value;
        }
      } else if (data_type === 'exact') {
        if (!['equals', 'not_equals'].includes(filter.condition)) {
          filterValid = false;
          if (displayErrors) {
            filter.conditionError = 'Select condition.';
          }
        }
      }
    }

    return filterValid;
  }

  @action
  applyQueryParamFilters(encodedFilters, dynamicView) {
    const filterGroups = JSON.parse(encodedFilters);
    filterGroups.forEach((group) => {
      const filterGroup = this.store.createRecord('filter-group', {
        dynamicView,
      });
      group.filters.forEach((filter) =>
        this.store.createRecord('filter', { filterGroup, ...filter })
      );
    });
  }

  @action
  prepareNewFilterGroup(dynamicView) {
    const filterGroup = this.store.createRecord('filter-group', {
      dynamicView,
    });
    this.store.createRecord('filter', { filterGroup });
  }

  @action
  prepareNewFilter(filterGroup) {
    this.store.createRecord('filter', { filterGroup });
  }

  @action
  removeFilter(filter) {
    const filterGroup = filter.filterGroup;
    if (filterGroup.filters.length === 1) {
      filterGroup.markedAsDeleted = true;
      filterGroup.deleteRecord();
      return;
    }

    filter.deleteRecord();
  }

  @action
  nullifyFilter(filter) {
    filter.field = null;
    filter.condition = null;
    filter.value = null;
  }

  @task({ drop: true })
  *saveViewFilters(dynamicView) {
    const isNew = dynamicView.isNew;
    if (!this.validateView(dynamicView) || !this.validateFilters(dynamicView)) {
      return;
    }

    dynamicView.filtersSaving = true;
    dynamicView.filtersSaved = false;

    // Add global views to current urlGroup
    if (!dynamicView.url) {
      dynamicView.urlGroup = this.dashboardState.currentUrlGroup;
    }

    if (isNew) yield dynamicView.save();
    yield this.saveFilterGroups.perform(dynamicView, isNew);

    this.metrics.trackEvent({
      event: 'Saved View',
      name: dynamicView.name,
    });
  }

  @task({ drop: true })
  *saveFilterGroups(dynamicView, isNew) {
    const tasks = [dynamicView.save()];
    dynamicView.filterGroups.forEach((fg) => tasks.push(fg.save()));

    yield all(tasks);

    yield dynamicView.reload();

    /*
      Must wait until after reload to set filtersSaving = false
      Keyword counts are hidden in the sidebar while filtersSaving === true
      For new views the response from `dynamicView.save()` will have an incorrect keyword count
      Follow-up response from `dynamicView.reload()` will correct this attribute
    */
    dynamicView.filtersSaving = false;
    dynamicView.filtersSaved = true;

    if (!isNew) return;

    // For newly created views, redirect to their keywords page
    const routeName = dynamicView.url
      ? 'dashboard.url.dynamic-view'
      : 'dashboard.dynamic-view.keywords';
    const models = dynamicView.url
      ? [dynamicView.url, dynamicView]
      : [dynamicView];
    this.router.transitionTo(routeName, ...models);
  }
}
