import ApplicationAdapter from './application';
import { isObjectType } from 'nightwatch-web/utils/is-type';

export default class KeywordAdapter extends ApplicationAdapter {
  coalesceFindRequests = false;

  buildURL(_modelName, _id, snapshot, requestType, query) {
    const defaultUrl = super.buildURL(...arguments);
    const urlId = query?.url_id ?? snapshot?.belongsTo('url')?.id;

    switch (requestType) {
      case 'findRecord':
        return defaultUrl;
      default:
        return defaultUrl.replace(/\/keywords/, `/urls/${urlId}/keywords`);
    }
  }

  handleResponse(status, headers, payload, requestData) {
    if (status >= 400) return super.handleResponse(...arguments);

    const meta = {
      total:
        payload['keyword_count'] ??
        headers['X-Total-Count'] ??
        headers['x-total-count'],
      pages:
        payload['page_count'] ??
        headers['X-Page-Count'] ??
        headers['x-page-count'],
    };

    if (Array.isArray(payload)) {
      payload = { keywords: payload, meta };
    } else if (isObjectType(payload)) {
      payload = { keyword: payload, meta };
    }
    return super.handleResponse(status, headers, payload, requestData);
  }

  // So that keyword .query() requests can be aborted
  query(_store, type, query, _recordArray, options) {
    let url = this.buildURL(type.modelName, null, null, 'query', query);

    if (this.sortQueryParams) {
      query = this.sortQueryParams(query);
    }

    return this.ajax(url, 'GET', {
      data: query,
      signal: options?.adapterOptions?.signal,
    });
  }
}
