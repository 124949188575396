import Model, { attr, belongsTo } from '@ember-data/model';
import { allSearchEngines } from 'nightwatch-web/constants/keyword-data';
import { resolve } from 'rsvp';

const engineIcon = allSearchEngines.reduce((acc, { id, icon }) => {
  acc[id] = icon;
  return acc;
}, {});

export default class Keyword extends Model {
  @attr('string') query;
  @attr('string') translation;
  @attr('string') position;
  @attr('string') previous_position;
  @attr('string') best_position;
  @attr('string') position_organic;
  @attr('string') position_places_image;
  @attr('string') position_local_pack;
  @attr('string') position_featured_snippet;
  @attr('string') position_knowledge_panel;
  @attr('date') created_at;
  @attr('date') last_processed_at;
  @attr('string') google_hl;
  @attr('string') google_gl;
  @attr('string') engine;
  @attr('string') result_url;
  @attr('string') url_priority;
  @attr('boolean') mobile;
  @attr('number') position_change;
  @attr('number') last_day_change;
  @attr('number') last_week_change;
  @attr('number') last_month_change;
  @attr() available_urls;
  @attr() historic_positions;
  @attr('date') last_historic_position_on;

  // dynamic columns
  @attr('number') click_potential_selected_interval;
  @attr('number') click_potential_change_interval;
  @attr('number') click_potential_change;
  @attr('number') search_volatility_change;
  @attr('number') search_volatility_change_interval;
  @attr('number') search_volatility_selected_interval;
  @attr('number') average_position_change;
  @attr('number') average_position_change_interval;
  @attr('number') average_position_selected_interval;
  @attr('number') traffic_value_selected_interval;
  @attr('number') traffic_value_change;

  // Search console
  @attr('number') clicks;
  @attr('number') impressions;
  @attr('number') ctr;
  @attr('number') search_console_position;
  @attr('number') results_count;
  @attr('string') adwords_global_average_cpc;
  @attr('string') adwords_global_search_volume;
  @attr('string') adwords_local_average_cpc;
  @attr('string') adwords_local_search_volume;
  @attr('string') adwords_location_id;
  @attr() last_position_change;
  @attr('string') local_search;
  @attr('string') uri;
  @attr() positionInfo;
  @attr() tags;
  @attr('string') position_type;
  @attr('string', { readOnly: true }) url_value;
  @attr() competitor_results;
  @attr() available_serp_features;
  @attr() precise_location;

  @belongsTo('url', { async: false }) url;

  get urlId() {
    /*
      ember-data expects keyword.url to be loaded – since it's an `{ async: false } relationship.
      This property is only accessed in one place (to link to SERP preview in the keyword table).
      On pages when the url may not be loaded (global dynamic views) accessing `keyword.url` will
      explode – but the id is safely accessible from the relationship like this:
    */
    return this.belongsTo('url').id();
  }

  get mobileReady() {
    return this.positionInfo?.mobile_ready;
  }

  get fullLocation() {
    const country = this.google_gl || '';
    const city = this.local_search || '';
    let location = `${country.toUpperCase()}`;
    if (city) {
      location += `, ${city}`;
    }
    // New York,New York,United States to New York, New York, United States
    return location
      .split(',')
      .map((l) => l.trim())
      .join(', ');
  }

  get fullLocationShortened() {
    const country = (this.google_gl || '').toUpperCase();
    const city = this.local_search;
    const location = city ? city : country;
    // New York,New York,United States to New York, New York, United States
    return location
      .split(',')
      .map((l) => l.trim())
      .join(', ');
  }

  get engineImgPath() {
    return engineIcon[this.engine];
  }

  get isGoogle() {
    return this.engine === 'google';
  }

  get isProcessing() {
    return !this.last_processed_at;
  }

  get evolutionGraphSeries() {
    return (this.historic_positions || []).slice(0, 10).reverse();
  }

  get rankingUrl() {
    return `${this.url_value}${this.uri}`;
  }

  set preciseLocation(location) {
    this.precise_location = location;
    if (!location) return;
    if (location?.id[0] === 'U') {
      this.local_search = location.id.substr(1);
    } else {
      this.adwords_location_id = location.id;
    }
  }

  get preciseLocation() {
    return this.precise_location;
  }

  get errorStrings() {
    return this.errors?.toArray()?.map((error) => error.message);
  }

  // No-op for bug where `reload()` is called on a deleted instance.
  reload() {
    if (this.isDeleted) return resolve(this);
    return super.reload();
  }
}
